import React from "react"

import { Container } from 'reactstrap'
import Link from '../../components/link' 
import LayoutEsp from '../../components/layoutEsp'
import SEO from "../../components/seo"
import Slider from '../../components/slider-service' 
import worldIcon from '../../assets/images/world.png' 
import truckAbsolute from '../../assets/images/truckAbsolute.png' 
import doorImg from '../../assets/images/doorImg.png' 
import doorIcon from '../../assets/images/doorIcon.png' 

export default () => (
  <LayoutEsp>
    <SEO title="Servicios" />
    <Slider title="SERVICIOS"/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
			  	<h4 className="surpassing"><b>SOBREPASANDO</b> TODAS LAS FRONTERAS DE <b>NOSOTROS</b> A <b>TI</b></h4>  
			  </div>
			</div>
		</Container>
	</div>
	<div className="point-crossed-background" >
		<img className="truckAbsolute" src={truckAbsolute} alt="truck"/> 
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<h4 className="serviceQuality">{'ALTA CALIDAD'}</h4> 
				<p className="serviceQualitySub">{'Los mejores tiempos de entrega'}</p>  
			  </div> 
			</div>
		</Container>
	</div>
	<div className="blue-service-background" >
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
				<img className="doorImg" src={doorImg} alt="door to door"/>
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock">
				<img className="doorIcon" src={doorIcon} alt="door Icon"/>
				<h4 className="serviceBlueH">SERVICIO <b>PUERTA A PUERTA</b></h4> 
				<p className="serviceBlueP">Somos especialistas en el transporte de mercadería desde 
											el punto de origen hasta el punto de destino, sin necesidad 
											de transbordar en todas las provincias canadienses, los 48 estados 
											de mejores tiempos de entrega para nuestros clientes.<br/><br/>
											 <b>Beneficios de trabajar con nosotros.</b> <br/><br/>
											 Transporte de ida y vuelta en ambos sentidos.<br/><br/>
											 Estamos capacitados para transportar envíos tanto en dirección norte como en dirección sur.<br/>
											 Somos un transportista de carril FAST aprobado (Free and Secure Trade para acelerar el cruce de fronteras) 
											 ya que nuestros conductores tienen este tipo de licencia de conducir.<br/>
				</p>
			  </div> 
			</div>
		</Container>
	</div>  
	<div className="blue-intermodal-background" >
		<div className="blue-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock"> 
				<h4 className="serviceBlueH">{'INTERMODAL'}</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP"> Contamos con más de 10 años de experiencia en el manejo de contenedores 
											 marítimos y ferroviarios en el área de <b>Los Ángeles C.A.</b><br/><br/> 
											 <b>Beneficios de trabajar con nosotros.</b><br/>
											 Conductores certificados con camiones 
											 certificados TWIC card 2013.<br/>
											 Alianzas con la mayoría de las navieras.<br/>
											 Permiso de acceso a terminales de embarque. 
				</p>
			  </div> 
			</div>
		</Container>
	</div>  
	<div className="red-logistic-background" >
		<div className="red-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock">  
				<h4 className="serviceBlueH">{'SERVICIO LOGISTICO'}</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP">  Apoyamos a nuestros clientes con diversas soluciones para el transporte de sus mercancías, 
											  como diseño de rutas, disposición de equipos, información en nuestro sitio web, 
											  citas de fechas de entrega o almacenamiento con nuestra empresa hermana. <a href="#" >A.G. Warehouse</a></p>
												
			  </div> 
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			</div>
		</Container>
	</div>   
	<div className="blue-bonded-background" >
		<div className="blue-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock"> 
				<h4 className="serviceBlueH">{'TRANSPORTISTA ADHERIDO'}</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP"> Nuestro Bound nos permite transportar mercancías desde<br/> 
											 de un país a otro.
				</p>
			  </div> 
			</div>
		</Container>
	</div>  
	<div className="red-chemical-background" >
		<div className="red-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock">  
				<h4 className="serviceBlueH">TRANSPORTE DE <b>QUIMICOS</b></h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP">Contamos con todos los permisos y licencias federales y estatales de México y Estados Unidos 
											para el transporte seguro de químicos no regulados a cualquier destino con conductores certificados..
				</p>
												
			  </div> 
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			</div>
		</Container>
	</div>   
	<div className="blue-warehouse-background" >
		<div className="blue-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock">   
				<h4 className="serviceBlueH">{'SERVICIO DE ALMACENES - CALEXICO,CA'}</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP">{'3PL Almacenaje\n'+ 
											 'Distribucion\n'+
											 'Cross Docking\n'+
											 'Transloading\n'+
											 'Palletized, Bulk o Heavy Loads\n'+
											 'FLT & LTL\n'+
											 'Inventory Control\n'+
											 '6,000 sq ft Cold Storage\n'+
											 'Truck & Trailer Yard\n'+
											 'Us Customs Brokerage\n'+
											 'C-TPAT Certified\n'+
											 'Bonded Warehouse Coming Soon!\n'
												}</p>
				
				<Link className="btn redButtonLink">AG Warehouse <i className="fa fa-caret-right"></i></Link> 
			  </div> 
			</div>
		</Container>
	</div>
	<div className="red-haul-background" >
		<div className="red-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock">  
				<h4 className="serviceBlueH">{'TRANSPORTE PESADO'}</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP"> Para satisfacer las necesidades de nuestros clientes para el transporte de cargas pesadas,<br/> 
											 Gutierrez Trucking pone a su disposición nuestra división de transporte pesado, totalmente equipada para transportar todo tipo de cargas pesadas.
				 
				</p>
												
			  </div> 
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			</div>
		</Container>
	</div>
	<div className="blue-operations-background" >
		<div className="blue-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock">   
				<h4 className="serviceBlueH serviceBlueHTiny">Operaciones <b>Dedicadas</b></h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP"> Contamos con el equipo y el especialista para atender cualquiera de los requerimientos especiales de su empresa
											 de manera dedicada para mantener la cadena de suministro lo más eficiente posible.<br/>
											 <br/>
											 <b>Por qué elegir un servicio dedicado?</b><br/>
											 <br/>
											  <b>Costo:</b><br/>
											 Un servicio dedicado permite un control de costos más preciso, evitando costos operativos adicionales.<br/>
											 <br/>
											  <b>Servicios:</b><br/>
											 Conductores y unidades dedicadas específicamente adaptadas a sus necesidades con la formación que su empresa requiere.<br/>
											 <br/>
											  <b>Capacidad:</b><br/>
											 Garantice la capacidad de entrega a sus clientes durante todo el año.<br/>
											 <br/>
											 <b>Compatibilidad:</b><br/>
											 Personal capacitado en todos los procesos internos de nuestros clientes haciendo que la cadena de suministro sea perfecta.<br/>
											 <br/>
			</p>
				 
			  </div> 
			</div>
		</Container>
	</div> 
	<div className="red-rent-background" >
		<div className="red-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">   
				<h4 className="serviceBlueH">ALQUILER DE <b>EQUIPO</b></h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP">Ponemos a su disposición nuestro completo inventario de equipos en alquiler. <br/> 
											  53" Dry vans.<br/>
											  48" Flatbeds.<br/>
											  Plataforma de paso para peso normal.<br/>
											  Reefers.<br/> 
				</p> 						
			  </div> 
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			</div>
		</Container>
	</div>  
	<div className="blue-refrigerated-background" >
		<div className="blue-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">   
				<h4 className="serviceBlueH"><b>REFRIGERADO</b></h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP">{'Nuestra flota refrigerada es capaz de transportar cualquier '+ 
											 'tipo de producto sensible a la temperatura gracias a nuestro sistema de monitorización de temperatura. '+
											 'Como cliente nuestro puede estar seguro de que su mercancía se mantendrá a la temperatura adecuada hasta que llegue a su destino.'
												}</p>
				 
			  </div> 
			</div>
		</Container>
	</div> 
  </LayoutEsp>
)
